import React from "react"
import { useColorMode } from "theme-ui"

import Columns from "../../components/columns"
import Column from "../../components/column"
import Text from "../../components/text"
import logoLiLand from "../../images/partner-logos/liland-bawue.svg"
import logoLiLandWhite from "../../images/partner-logos/liland-bawue-white.svg"

const AttributionLiland = () => {
  const [colorMode] = useColorMode()

  return (
    <Columns
      alignY="center"
      space={[6, 12]}
      collapse={[true, false]}
      reverse={[false, true]}
    >
      <Column>
        <Text>
          Gefördert aus Landesmitteln durch die Arbeitsstelle für literarische
          Museen in Baden-Württemberg (DLA Marbach).
        </Text>
      </Column>
      <Column width="content">
        <img
          src={colorMode === "light" ? logoLiLand : logoLiLandWhite}
          alt="Logo Literaturland Baden-Württemberg"
          width="82"
          height="92"
          style={{ width: "auto", height: "92px" }}
        />
      </Column>
    </Columns>
  )
}

export default AttributionLiland
